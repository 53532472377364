import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Home from "./Home";
import Search from "./Search";
import Index from "./MyPage";
import { useHistory } from "react-router-dom";
import Favorite from "./Favorite";
import useScreenOrientation from "../../hooks/useScreenOrientation";
import { useRecoilState } from "recoil";
import { navigatorShadowState } from "../../recoil/navigator";

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 69px;
  -webkit-overflow-scrolling: touch;
`;

const BottomNavigationWrapper = styled.div<{ $isShadow?: boolean }>`
  position: fixed;
  width: 100%;
  max-width: 500px;
  background: white;
  bottom: 0;
  padding: 9px 16px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ $isShadow = true }) =>
    $isShadow &&
    `
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px 0px;
  `}
  z-index: 120;

  > .item {
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > img {
      width: 24px;
      height: 24px;
    }
  }
  > .item.selected {
    width: auto;
    display: flex;
    height: 38px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 999px;
    background: var(--Purple-300, #6436e7);
    transition: transform 0.1s ease;
    > img {
      width: 24px;
      height: 24px;
    }
    > span {
      color: #fff;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%; /* 18px */
    }
  }
`;

type MenuType = "home" | "like" | "search" | "mypage";
const Main = () => {
  const orientation = useScreenOrientation();
  const history = useHistory();
  const path = history.location.pathname;
  const [selectedMenu, setSelectedMenu] = useState<MenuType>();
  const [navigatorShadow, setNavigatorShadow] =
    useRecoilState(navigatorShadowState);
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = (e: Event) => {
      // home 메뉴일 때만 스크롤 제어
      if (selectedMenu !== "home") return;

      const element = e.target as HTMLElement;
      const st = element.scrollTop;
      // 아래로 스크롤 시도할 때
      if (st < 0 && st < lastScrollTop) {
        element.scrollTop = lastScrollTop;
      }

      lastScrollTop = st <= 0 ? 0 : st;
    };

    const handleTouchMove = (e: TouchEvent) => {
      // home 메뉴일 때만 터치 제어
      if (selectedMenu !== "home") return;

      const element = mainWrapperRef.current;
      if (
        element &&
        element.scrollTop < 0 &&
        element.scrollTop >= element.scrollHeight - element.clientHeight
      ) {
        e.preventDefault();
      }
    };

    const mainWrapper = mainWrapperRef.current;
    if (mainWrapper) {
      mainWrapper.addEventListener("scroll", handleScroll);
      mainWrapper.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (mainWrapper) {
        mainWrapper.removeEventListener("scroll", handleScroll);
        mainWrapper.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [selectedMenu]);

  useEffect(() => {
    setNavigatorShadow(true);
    if (path === "/") {
      history.push("/home");
    } else {
      const tab = path.split("/")[1] as MenuType;
      setSelectedMenu(tab ? tab : "home");
    }
  }, [path]);

  const onClickMenu = (menu: MenuType) => {
    if (selectedMenu === menu) {
      return;
    }

    const state: { [key: string]: any } = {};
    if (menu === "search") {
      state.mode = "query";
    }

    history.push(`/${menu}`, state);
  };

  return (
    <MainWrapper ref={mainWrapperRef}>
      {selectedMenu === "home" && <Home />}
      {selectedMenu === "search" && <Search />}
      {selectedMenu === "mypage" && <Index />}
      {selectedMenu === "like" && <Favorite />}
      <BottomNavigationWrapper $isShadow={navigatorShadow}>
        {selectedMenu === "home" ? (
          <div className="item selected">
            <img src="/image-web/bottom-nav/renewal/home-active.svg" />
            <span>HOME</span>
          </div>
        ) : (
          <div className="item">
            <img
              src="/image-web/bottom-nav/renewal/home.svg"
              alt="홈"
              onClick={() => onClickMenu("home")}
            />
          </div>
        )}
        {selectedMenu === "like" ? (
          <div className="item selected">
            <img src="/image-web/bottom-nav/renewal/like-active.svg" />
            <span>LIKES</span>
          </div>
        ) : (
          <div className="item">
            <img
              src="/image-web/bottom-nav/renewal/like.svg"
              alt="즐겨찾기"
              onClick={() => onClickMenu("like")}
            />
          </div>
        )}
        {selectedMenu === "search" ? (
          <div className="item selected">
            <img src="/image-web/bottom-nav/renewal/search-active.svg" />
            <span>PUB</span>
          </div>
        ) : (
          <div className="item">
            <img
              src="/image-web/bottom-nav/renewal/search.svg"
              alt="검색"
              onClick={() => onClickMenu("search")}
            />
          </div>
        )}
        {selectedMenu === "mypage" ? (
          <div className="item selected">
            <img src="/image-web/bottom-nav/renewal/my-active.svg" />
            <span>MY</span>
          </div>
        ) : (
          <div className="item">
            <img
              src="/image-web/bottom-nav/renewal/my.svg"
              alt="마이페이지"
              onClick={() => onClickMenu("mypage")}
            />
          </div>
        )}
      </BottomNavigationWrapper>
    </MainWrapper>
  );
};
export default Main;
