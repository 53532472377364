import instance from "../utils/network";
import {
  Cafe,
  CommonResponse,
  GameType,
  PubTournament,
  PubType,
} from "./types";

export interface PubSearchParams {
  data?: number[];
  q?: string;
  lat?: number;
  lon?: number;
  myLat?: number;
  myLon?: number;
  km?: number;
  pubTypes?: PubType[];
  gameTypes?: GameType[];
  buyInFrom?: number;
  buyInTo?: number;
  blindUpFrom?: number;
  blindUpTo?: number;
  prizeFrom?: number;
  prizeTo?: number;
  sort?: string;
}

export function searchNestCafe(data: PubSearchParams): Promise<Cafe[]> {
  return instance.post("/nest/cafe/search", data);
}

export function reviewDataSet(id: number) {
  return instance.put(`/nest/cafe/review/${id}`, null);
}

export function searchCafe(data: PubSearchParams): Promise<Cafe[]> {
  return instance.post("/search/cafe", data);
}

export function areaCafeList(
  areaProvinceId: number,
  areaCityId: number
): Promise<Cafe[]> {
  return instance.get("/cafe/areaCafeList", {
    params: { areaProvinceId, areaCityId },
  });
}

export function searchAreaCafe(cityName: string): Promise<Cafe[]> {
  return instance.get("/cafe/cityCafeList", { params: { cityName } });
}

export function cafeDetail(id: number): Promise<Cafe> {
  return instance.get("/nest/cafe/" + id);
}

export function cafeTournament(id: number): Promise<PubTournament[]> {
  return instance.get("/nest/seller/tournament/pub-not-end/" + id);
}

export function cafeTournamentsStarted(
  cafeList: Cafe[]
): Promise<PubTournament[]> {
  const cafeIds = cafeList.map((cafe) => cafe.id);
  return instance.post("/nest/seller/tournament/pubs", { cafeIds });
}

export function cafeTournamentsWithNotStarted(
  cafeList: Cafe[]
): Promise<PubTournament[]> {
  const cafeIds = cafeList.map((cafe) => cafe.id);
  return instance.post("/nest/seller/tournament/pubs-with-not-start", {
    cafeIds,
  });
}

export function cafeTournaments(cafeList: Cafe[]): Promise<PubTournament[]> {
  return cafeTournamentsStarted(cafeList);
}

export function cafeTournamentEnd(id: number): Promise<any> {
  return instance.get("/nest/seller/tournament/timer-by-user/" + id);
}

export function cafeSelectReview(data: {
  cafeId: number;
  review: number;
}): Promise<CommonResponse> {
  return instance.post("/cafe/selectReview", null, { params: data });
}

export function cafeReports(data: {
  cafeId: number;
  info: string;
}): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/reports", formData);
}

export function AddReview(
  data: { pubId: number; message: string; score: number },
  imageFiles: File[]
) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("imageList", file);
  }

  return instance.post("/cafe/review", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function UpdateReview(
  data: { pubId: number; message: string; score: number; id: number },
  imageFiles: File[]
) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("imageList", file);
  }

  return instance.put(`/cafe/review/${data.id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function RemoveReview(id: number): Promise<CommonResponse> {
  return instance.delete(`/cafe/review/${id}`);
}

// admin api

export function cafeRegistReviewPhoto(
  cafeId: number,
  image: File
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append("image", image);

  return instance.put("/cafe/registReviewPhoto", formData, {
    params: { cafeId },
  });
}

export function cafeDeleteReviewPhoto(params: {
  cafeId: number;
  photoId: number;
}): Promise<CommonResponse> {
  return instance.delete("/cafe/deleteReviewPhoto", { params });
}

export function setReviewImageOrder(
  pubId: number,
  data: {
    reviewImageOrder: { id: number }[];
  }
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/setReviewImageOrder", formData, {
    params: { pubId },
  });
}

interface EventRegisterParams {
  title: string;
  description: string;
  startAt: string;
  closeAt: string;
  cafeId: number;
}

export function cafeEventRegister(
  data: EventRegisterParams
): Promise<CommonResponse> {
  return instance.post("/cafe-event/register", data);
}

interface EventUpdateParams extends EventRegisterParams {
  id: number;
}

export function cafeEventUpdate(
  data: EventUpdateParams
): Promise<CommonResponse> {
  return instance.put("/cafe-event/update ", data);
}

export function cafeEventDelete(params: {
  id: number;
}): Promise<CommonResponse> {
  return instance.delete("/cafe-event/delete", { params });
}

interface CafeTournament {
  title: string;
  startAt: string;
  endAt: string;
}

interface CafeTournamentResponse extends CafeTournament {
  imageList: string[];
}

export function cafeRegisterTournament(
  cafeId: number,
  images: File[],
  data: CafeTournament
): Promise<CafeTournamentResponse> {
  const formData = new FormData();
  for (let image of images) {
    formData.append("images", image);
  }
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/registerTournament", formData, {
    params: { cafeId },
  });
}

export function cafeModifyTournament(
  cafeId: number,
  tournamentId: number,
  images: File[],
  data: CafeTournament
): Promise<CafeTournamentResponse> {
  const formData = new FormData();
  for (let image of images) {
    formData.append("images", image);
  }
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/modifyTournament", formData, {
    params: { cafeId, tournamentId },
  });
}

export function cafeDeleteTournament(
  cafeId: number,
  tournamentId: number
): Promise<CommonResponse> {
  return instance.delete("/cafe/deleteTournament", {
    params: { cafeId, tournamentId },
  });
}
