import instance from "../utils/network";
import { Cafe, HoldemNowType, PubType } from "./types";

export type LikeType = "CAFE" | "PRODUCT";

export function likeItems(data: {
  userId: number;
  itemType: LikeType;
  lat: number;
  lon: number;
  pubTypes?: PubType[];
  buyInFrom?: number;
  buyInTo?: number;
  blindUpFrom?: number;
  blindUpTo?: number;
  prizeFrom?: number;
  prizeTo?: number;
  sort?: string;
}): Promise<Cafe[]> {
  return instance.post("/like/items", data);
}

export function likeHasItems(params: {
  userId: number;
  itemId: number;
  itemType: LikeType;
}): Promise<{
  hasItem: boolean;
}> {
  return instance.get("/like/hasItem", { params });
}

export function likeAdd(data: {
  userId: number;
  itemId: number;
  likeType: "CAFE" | "PRODUCT";
}): Promise<Cafe[]> {
  return instance.post("/like/add", data);
}

export function likeDelete(data: {
  userId: number;
  itemId: number;
  likeType: LikeType;
}): Promise<Cafe[]> {
  return instance.post("/like/delete", data);
}

export function cafeViewMessage(params: {
  messageId: number;
}): Promise<HoldemNowType> {
  return instance.get("/cafe/viewMessage", { params });
}
